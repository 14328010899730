import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  FormControl,
  FormLabel,
  Flex,
  Text,
  Stack,
  Select,
  Textarea,
  useToast
} from '@chakra-ui/react';

import * as FiIcons from 'react-icons/fi';
import theme from "../../../global/theme";
import { pushNotifications } from "../../../../store/notifications/notificationsSlice";


const PushNotificationsModal = ({ onClose }) => {
  const dispatch = useDispatch();
  const notifications = useSelector(state => state.notifications);

  const { t } = useTranslation();
  const toast = useToast({ duration: 3000, status: "success", position: "top" });

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  return (
    <Modal isOpen={true} onClose={onClose} size="md">
      <ModalOverlay />
      <ModalContent borderRadius={4} paddingBlock={4} bg={theme.dark}>
        <form onSubmit={handleSubmit((values, event) => {

          dispatch(pushNotifications(values))
            .unwrap()
            .then(_ => {
              toast({ description: `message sent to ${values.to} successfully` });
              onClose();
            }).catch(e => console.log(e));
        })}>
          <ModalHeader textAlign="center" color="orange" textTransform="uppercase" fontSize={22}>
            {t('general.create')}
          </ModalHeader>
          <ModalBody>
            <Stack spacing={4}>
              <FormControl>
                <FormLabel color={theme.light} fontWeight="bold" textTransform="capitalize">
                  {t('general.push_notifications')}
                </FormLabel>
                <Select bg={theme.light} border="none" borderRadius={4}
                  {...register("to")}
                >
                  <option value="user">users</option>
                  <option value="owner">owners</option>
                </Select>
              </FormControl>

              <FormControl>
                <FormLabel color={theme.light} fontWeight="bold" textTransform="capitalize">
                  {t('general.message')}
                </FormLabel>
                <Textarea bg={theme.light} border="none" borderRadius={4}
                  placeholder={t('general.message')} _placeholder={{ textTransform: 'capitalize' }}
                  {...register("message", {
                    required: `${t('validation.required')}`
                  })}
                />
                {errors.message?.message &&
                  <Text color="red.600" marginTop={2}>{errors.message.message}</Text>}
              </FormControl>
            </Stack>

          </ModalBody>
          <ModalFooter>
            <Flex justifyContent="flex-end">
              <Button
                type="submit"
                rightIcon={<FiIcons.FiSave />}
                color="white" bg="green" paddingInline={4}
                paddingBlock={2} height="auto" textTransform="capitalize"
                isLoading={notifications.isLoading}
                _hover={{ background: 'green' }}
              >
                {t('general.save')}
              </Button>
              <Button
                type="button"
                rightIcon={<FiIcons.FiMinimize2 />}
                color="white" bg="red.600" paddingInline={4}
                paddingBlock={2} height="auto" textTransform="capitalize"
                marginInlineStart={4}
                isLoading={notifications.isLoading}
                _hover={{ background: 'red.600' }}
                onClick={onClose}
              >
                {t('general.close')}
              </Button>
            </Flex>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}

export default PushNotificationsModal