import styled from 'styled-components';

export const AuthWarpper = styled.section`
  display: flex;
  flex-wrap: wrap;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.light};
  .lang-btn {
    position: fixed;
    top: 1rem;
    ${({ theme }) => theme.start}: 1rem;
  }
  .auth-block {
    width: 100%;
    padding: 1rem;
    margin-block: 2rem;
    @media only screen and (min-width: 992px) {
      width: 50%;
      padding-block: 1rem;
      padding-inline: 4rem;
    }
    @media only screen and (min-width: 1260px) {
      width: 50%;
      padding-block: 1rem;
      padding-inline: 7rem;
    }
    .content {
      padding: 1rem;
      border: 1px solid ${({ theme }) => theme.border};
      @media only screen and (min-width: 992px) {
        padding-block: 3rem;
        padding-inline: 2rem;
      }
    }
  }
  .auth-bg {
    display: none;
    @media only screen and (min-width: 992px) {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
    }
  }
`;