import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { apiUri } from "../../utilities/apiManager";

let initialState = {
  isLoading: false,
  errors: [],
  data: {}
};

export const getInfo = createAsyncThunk(
  "info/getInfo",
  async (_, thunkApi) => {
    try {
      const { data } = await axios.get(
        `${apiUri}/settings/info`,
        {
          headers: {
            "auth-token": "Bearer " + thunkApi.getState().auth.accessToken,
            "Accept-Language": document.documentElement.lang,
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    }
    catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

export const updateInfo = createAsyncThunk(
  "info/updateInfo",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.put(
        `${apiUri}/settings/info`,
        args,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            "auth-token": "Bearer " + thunkApi.getState().auth.accessToken,
            "Accept-Language": document.documentElement.lang,
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    }
    catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

const infoSlice = createSlice({
  name: "info",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getInfo.pending, (state) => {
      state.isLoading = true;
      state.errors = [];
    })
      .addCase(getInfo.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.errors = [];
        state.data = payload;
      })
      .addCase(getInfo.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errors = payload.errors;
      })

      .addCase(updateInfo.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(updateInfo.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.errors = [];
        state.data = payload;
      })
      .addCase(updateInfo.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errors = payload.errors;
      })
  }
});


export default infoSlice.reducer;