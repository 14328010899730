import { FooterWrapper } from './FooterStyle';

const Footer = () => {
  return (
    <FooterWrapper>
      copyrights reserved &copy; 2023 to <span>Echo soft</span>
    </FooterWrapper>
  )
}

export default Footer