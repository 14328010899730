import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiUri } from "../../utilities/apiManager";

let initialState = {
  isLoading: false,
  videos: 0,
  properties: 0,
  users: 0,
  plans: 0,
  topViewProperties: [],
  topViewVideos: [],
  topOwners: [],
  topPlans: [],
  errors: []
}

export const getStatics = createAsyncThunk(
  "statics/getStatics",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `${apiUri}/statics`,
        {
          headers: {
            'Content-Type': 'application/json',
            "auth-token": "Bearer " + thunkApi.getState().auth.accessToken,
            "Accept-Language": document.documentElement.lang,
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

const staticsSlice = createSlice({
  name: "statics",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getStatics.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(getStatics.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.videos = payload.videos;
        state.properties = payload.properties;
        state.users = payload.users;
        state.plans = payload.plans;
        state.topViewProperties = payload.topViewProperties;
        state.topViewVideos = payload.topViewVideos;
        state.topOwners = payload.topOwners;
        state.topPlans = payload.topPlans;
        state.errors = [];
      })
      .addCase(getStatics.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errors = payload.errors
      })
  }
});

export default staticsSlice.reducer;