import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiUri, filterRequest } from "../../utilities/apiManager";

let initialState = {
  isLoading: false,
  data: [],
  itemsCount: 0,
  pages: 0,
  errors: [],
}

export const getPrivacyPolicy = createAsyncThunk(
  "privacyPolicy/getPrivacyPolicy",
  async (args, thunkApi) => {
    try {
      const { page, size = 10, query } = args;
      const filter = filterRequest(args.filter);
      const { data } = await axios.get(
        `${apiUri}/settings/privacy_policy?page=${page}&size=${size}&query=${query}${filter}`,
        {
          headers: {
            'Content-Type': 'application/json',
            "auth-token": "Bearer " + thunkApi.getState().auth.accessToken,
            "Accept-Language": document.documentElement.lang,
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

export const createPrivacyPolicy = createAsyncThunk(
  "privacyPolicy/createPrivacyPolicy",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.post(
        `${apiUri}/settings/privacy_policy`,
        args,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            "auth-token": "Bearer " + thunkApi.getState().auth.accessToken,
            "Accept-Language": document.documentElement.lang,
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    }
    catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

export const deletePrivacyPolicy = createAsyncThunk(
  "privacyPolicy/deletePrivacyPolicy",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.delete(
        `${apiUri}/settings/privacy_policy/${args._id}`,
        {
          headers: {
            "auth-token": "Bearer " + thunkApi.getState().auth.accessToken,
            "Accept-Language": document.documentElement.lang,
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    }
    catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
)

const privacyPolicySlice = createSlice({
  name: "privacyPolicy",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getPrivacyPolicy.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(getPrivacyPolicy.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = payload.data;
        state.itemsCount = payload.itemsCount;
        state.pages = payload.pages;
        state.errors = [];
      })
      .addCase(getPrivacyPolicy.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errors = payload.errors;
      })

      .addCase(createPrivacyPolicy.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(createPrivacyPolicy.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.itemsCount++;
        state.data.push(payload.data);
        state.errors = [];
      })
      .addCase(createPrivacyPolicy.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errors = payload.errors;
      })

      .addCase(deletePrivacyPolicy.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(deletePrivacyPolicy.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = state.data.filter(el => el._id !== payload.data._id);
        state.errors = [];
      })
      .addCase(deletePrivacyPolicy.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errors = payload.errors;
      })
  }
});

export default privacyPolicySlice.reducer;