import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiUri, filterRequest } from "../../utilities/apiManager";

let initialState = {
  isLoading: false,
  data: [],
  itemsCount: 0,
  pages: 0,
  errors: [],
}

export const getVideos = createAsyncThunk(
  "videos/getVideos",
  async (args, thunkApi) => {
    try {
      const { page, size = 10, query, } = args;
      const filter = filterRequest(args.filter);
      const { data } = await axios.get(
        `${apiUri}/videos?page=${page}&size=${size}&query=${query}${filter}`,
        {
          headers: {
            'Content-Type': 'application/json',
            "auth-token": "Bearer " + thunkApi.getState().auth.accessToken,
            "Accept-Language": document.documentElement.lang,
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      console.log(errors);
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

export const createVideo = createAsyncThunk(
  "videos/createVideo",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.post(
        `${apiUri}/videos`,
        args,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "auth-token": "Bearer " + thunkApi.getState().auth.accessToken,
            "Accept-Language": document.documentElement.lang,
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    }
    catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

export const updateVideo = createAsyncThunk(
  "videos/updateVideo",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.put(
        `${apiUri}/videos/status/${args._id}`,
        args,
        {
          headers: {
            "auth-token": "Bearer " + thunkApi.getState().auth.accessToken,
            "Accept-Language": document.documentElement.lang,
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    }
    catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

export const deleteVideo = createAsyncThunk(
  "videos/deleteVideo",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.delete(
        `${apiUri}/videos/${args._id}`,
        {
          headers: {
            "auth-token": "Bearer " + thunkApi.getState().auth.accessToken,
            "Accept-Language": document.documentElement.lang,
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    }
    catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

const videosSlice = createSlice({
  name: "videos",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getVideos.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(getVideos.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = payload.data;
        state.itemsCount = payload.itemsCount;
        state.pages = payload.pages;
        state.errors = [];
      })
      .addCase(getVideos.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errors = payload.errors
      })

      .addCase(createVideo.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(createVideo.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data.push(payload.data);
        state.itemsCount++;
        state.errors = [];
      })
      .addCase(createVideo.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errors = payload.errors;
      })

      .addCase(updateVideo.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(updateVideo.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        const indexAt = state.data.findIndex(el => el._id === payload.data._id);
        state.data[indexAt] = payload.data;
        state.errors = [];
      })
      .addCase(updateVideo.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errors = payload.errors;
      })

      .addCase(deleteVideo.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(deleteVideo.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = state.data.filter(el => el._id !== payload.data._id);
        state.errors = [];
      })
      .addCase(deleteVideo.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errors = payload.errors;
      })
  }
});

export default videosSlice.reducer;