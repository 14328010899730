import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiUri, filterRequest } from "../../utilities/apiManager";

let initialState = {
  isLoading: false,
  data: [],
  itemsCount: 0,
  pages: 0,
  errors: [],
}

export const getWallet = createAsyncThunk(
  "wallet/getWallet",
  async (args, thunkApi) => {
    try {
      const { page, size = 10, query } = args;
      const filter = filterRequest(args.filter);
      const { data } = await axios.get(
        `${apiUri}/wallet/recharge?page=${page}&size=${size}&query=${query}${filter}`,
        {
          headers: {
            'Content-Type': 'application/json',
            "Accept-Language": document.documentElement.lang,
            "auth-token": "Bearer " + thunkApi.getState().auth.accessToken
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      console.log(errors);
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

const walletSlice = createSlice({
  name: "wallet",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getWallet.pending, (state) => {
        state.isLoading = true;
        state.errors = []
      })
      .addCase(getWallet.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.errors = [];
        state.data = payload.data;
        state.itemsCount = payload.itemsCount;
        state.pages = payload.pages;
      })
      .addCase(getWallet.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errors = payload.errors;
      })
  }
});

export default walletSlice.reducer;