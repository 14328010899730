import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUri, filterRequest } from "../../utilities/apiManager";
import axios from "axios";

let initialState = {
  isLoading: false,
  data: [],
  itemsCount: 0,
  pages: 0,
  errors: [],
}

export const getDeduction = createAsyncThunk(
  "deduction/getDeduction",
  async (args, thunkApi) => {
    try {
      const { page, size = 10, query } = args;
      const filter = filterRequest(args.filter);
      const { data } = await axios.get(
        `${apiUri}/deduction?page=${page}&size=${size}&query=${query}${filter}`,
        {
          headers: {
            "Content-Type": "application/json",
            "Accept-Language": document.documentElement.lang,
            "auth-token": "Bearer " + thunkApi.getState().auth.accessToken
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      console.log(errors);
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

export const createDeduction = createAsyncThunk(
  "deduction/createDeduction",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.post(
        `${apiUri}/deduction`,
        args,
        {
          headers: {
            "auth-token": "Bearer " + thunkApi.getState().auth.accessToken,
            "Accept-Language": document.documentElement.lang,
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    }
    catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

export const updateDeduction = createAsyncThunk(
  "deduction/updateDeduction",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.put(
        `${apiUri}/deduction/${args._id}`,
        args,
        {
          headers: {
            "auth-token": "Bearer " + thunkApi.getState().auth.accessToken,
            "Accept-Language": document.documentElement.lang,
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    }
    catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

export const deleteDeduction = createAsyncThunk(
  "deduction/deleteDeduction",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.delete(
        `${apiUri}/deduction/${args._id}`,
        {
          headers: {
            "auth-token": "Bearer " + thunkApi.getState().auth.accessToken,
            "Accept-Language": document.documentElement.lang,
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    }
    catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

const deductionSlice = createSlice({
  name: "deduction",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getDeduction.pending, (state) => {
        state.isLoading = true;
        state.errors = []
      })
      .addCase(getDeduction.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.errors = [];
        state.data = payload.data;
        state.itemsCount = payload.itemsCount;
        state.pages = payload.pages;
      })
      .addCase(getDeduction.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errors = payload.errors;
      })

      .addCase(createDeduction.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(createDeduction.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data.push(payload.data);
        state.itemsCount++;
        state.errors = [];
      })
      .addCase(createDeduction.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errors = payload.errors;
      })

      .addCase(updateDeduction.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(updateDeduction.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        const indexAt = state.data.findIndex(el => el._id === payload.data._id);
        state.data[indexAt] = payload.data;
        state.errors = [];
      })
      .addCase(updateDeduction.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errors = payload.errors;
      })

      .addCase(deleteDeduction.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(deleteDeduction.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = state.data.filter(el => el._id !== payload.data._id);
        state.errors = [];
      })
      .addCase(deleteDeduction.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errors = payload.errors;
      })
  }
});

export default deductionSlice.reducer;