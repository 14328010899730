import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiUri, filterRequest } from "../../utilities/apiManager";

let initialState = {
  isLoading: false,
  data: [],
  itemsCount: 0,
  pages: 0,
  errors: [],
}

export const getDisclaimers = createAsyncThunk(
  "disclaimers/getDisclaimers",
  async (args, thunkApi) => {
    try {
      const { page, size = 10, query } = args;
      const filter = filterRequest(args.filter);
      const { data } = await axios.get(
        `${apiUri}/disclaimer?page=${page}&size=${size}&query=${query}${filter}`,
        {
          headers: {
            "Content-Type": "application/json",
            "Accept-Language": document.documentElement.lang,
            "auth-token": thunkApi.getState().auth.accessToken
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      console.log(errors);
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

export const sendDocument = createAsyncThunk(
  "disclaimers/sendDocument",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.post(
        `${apiUri}/disclaimer/${args._id}/send_document`,
        args,
        {
          headers: {
            "Content-Type": "application/json",
            "Accept-Language": document.documentElement.lang,
            "auth-token": thunkApi.getState().auth.accessToken
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    }
    catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

const disclaimersSlice = createSlice({
  name: "disclaimers",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getDisclaimers.pending, (state) => {
      state.isLoading = true;
      state.errors = [];
    })
    builder.addCase(getDisclaimers.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = payload.data;
      state.pages = payload.pages;
      state.itemsCount = payload.itemsCount;
      state.errors = [];
    })
    builder.addCase(getDisclaimers.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload.errors;
    })

    builder.addCase(sendDocument.pending, (state) => {
      state.isLoading = true;
      state.errors = [];
    })
    builder.addCase(sendDocument.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [];
    })
    builder.addCase(sendDocument.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload.errors;
    })
  }
});

export default disclaimersSlice.reducer;