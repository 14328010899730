import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiUri, filterRequest } from "../../utilities/apiManager";

let initialState = {
  isLoading: false,
  data: [],
  itemsCount: 0,
  pages: 0,
  errors: [],
}

export const getContracts = createAsyncThunk(
  "contracts/getContracts",
  async (args, thunkApi) => {
    try {
      const { page, size = 10, query } = args;
      const filter = filterRequest(args.filter);
      const { data } = await axios.get(
        `${apiUri}/settings/contracts?page=${page}&size=${size}&query=${query}${filter}`,
        {
          headers: {
            'Content-Type': 'application/json',
            "auth-token": "Bearer " + thunkApi.getState().auth.accessToken,
            "Accept-Language": document.documentElement.lang,
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

export const createContract = createAsyncThunk(
  "contracts/createContract",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.post(
        `${apiUri}/settings/contracts`,
        args,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            "auth-token": "Bearer " + thunkApi.getState().auth.accessToken,
            "Accept-Language": document.documentElement.lang,
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    }
    catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

export const updateContract = createAsyncThunk(
  "contracts/updateContract",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.put(
        `${apiUri}/settings/contracts/${args._id}`,
        args.values,
        {
          headers: {
            "auth-token": "Bearer " + thunkApi.getState().auth.accessToken,
            "Accept-Language": document.documentElement.lang,
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    }
    catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

export const deleteContract = createAsyncThunk(
  "contracts/deleteContract",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.delete(
        `${apiUri}/settings/contracts/${args._id}`,
        {
          headers: {
            "auth-token": "Bearer " + thunkApi.getState().auth.accessToken,
            "Accept-Language": document.documentElement.lang,
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    }
    catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
)

const contractsSlice = createSlice({
  name: "contracts",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getContracts.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(getContracts.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = payload.data;
        state.itemsCount = payload.itemsCount;
        state.pages = payload.pages;
        state.errors = [];
      })
      .addCase(getContracts.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errors = payload.errors;
      })

      .addCase(createContract.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(createContract.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.itemsCount++;
        state.data.push(payload.data);
        state.errors = [];
      })
      .addCase(createContract.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errors = payload.errors;
      })

      .addCase(updateContract.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(updateContract.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        const indexAt = state.data?.findIndex(el => el._id === payload.data._id);
        state.data[indexAt] = payload.data;
        state.errors = [];
      })
      .addCase(updateContract.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errors = payload.errors;
      })

      .addCase(deleteContract.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(deleteContract.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = state.data.filter(el => el._id !== payload.data._id);
        state.errors = [];
      })
      .addCase(deleteContract.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errors = payload.errors;
      })
  }
});

export default contractsSlice.reducer;