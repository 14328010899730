import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import {
  Box, Button,
  FormControl,
  FormLabel,
  Heading,
  Input, Stack,
  Text,
  Image,
  Divider,
  useToast,
  InputGroup,
  InputRightElement
} from '@chakra-ui/react';

import * as FiIcons from 'react-icons/fi';

import theme from '../../global/theme';

import { login } from '../../../store/auth/authSlice';

import { AuthWarpper } from './AuthStyle';

import brandImage from '../../../assets/images/logo.png';
import authBg from "../../../assets/images/authBg.png";

const Login = () => {
  const { t, i18n } = useTranslation();
  const toast = useToast({ position: 'top', duration: 2000, status: 'error' });
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);

  const { register, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      role: "admin"
    }
  });

  const [isPasswordShown, setIsPasswordShown] = useState(false);

  return (
    <AuthWarpper>
      <Button
        type="button" className="lang-btn"
        onClick={() => i18n.changeLanguage(i18n.language === "en" ? "ar" : "en")}
      >
        <FiIcons.FiGlobe size={22} />
      </Button>
      <Box className="auth-block" display="flex" alignItems="center" justifyContent="center">
        <Box
          bg={theme.light} boxShadow={theme.shadow}
          w="100%" borderRadius={10}
          className="content"
        >

          <Box textAlign="center">
            <Image m="auto" h="120px" src={brandImage} mb={4} />
            <Heading
              color={theme.dark} fontSize={28} textTransform="capitalize" mb={4}
            >
              {t('pages.auth.welcome_1')}
            </Heading>
            <Heading
              color={theme.text} fontSize={14} textTransform="capitalize"
            >
              {t('pages.auth.welcome_2')}
            </Heading>
          </Box>

          <Divider borderColor={theme.border} marginBlock={6} />

          <form onSubmit={handleSubmit(values => {
            dispatch(login(values)).unwrap().then(_ => {
              navigate("/", {
                replace: true
              });
            }).catch(error => {
              toast({
                description: error.errors && error.errors[0]?.msg,
              });
            })
          })}>
            <Stack spacing={4}>

              <FormControl>
                <FormLabel
                  color={theme.dark} textTransform="capitalize"
                >
                  {t('pages.auth.email')}
                </FormLabel>
                <Input
                  type="text" placeholder={t('pages.auth.email')}
                  border="none" color={theme.dark} bg={theme.bg}
                  _placeholder={{ color: theme.text }} paddingInline={4}
                  {...register('email', {
                    required: `${t('validation.required')}`
                  })}
                />
                {errors.email?.message && (
                  <Text color={theme.error}>{errors.email?.message}</Text>
                )}
              </FormControl>

              <FormControl>
                <FormLabel
                  color={theme.dark} textTransform="capitalize"
                >
                  {t('pages.auth.password')}
                </FormLabel>
                <InputGroup>
                  <Input
                    type={isPasswordShown ? 'text' : 'password'} placeholder={t('pages.auth.password')}
                    border="none" color={theme.dark} bg={theme.bg} autoComplete="false"
                    _placeholder={{ color: theme.text }} paddingInline={4}
                    {...register('password', {
                      required: `${t('validation.required')}`,
                      minLength: {
                        value: 6,
                        message: `${t('validation.min_length')} 6`
                      }
                    })}
                  />
                  <InputRightElement>
                    <Button p={0} bg="none" color={theme.dark} _hover={{ bg: "none" }}
                      type="button" onClick={() => setIsPasswordShown(!isPasswordShown)}
                    >
                      {isPasswordShown ? <FiIcons.FiEyeOff /> : <FiIcons.FiEye />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                {errors.password?.message && (
                  <Text color={theme.error}>{errors.password?.message}</Text>
                )}
              </FormControl>

              <Button
                type="submit" textTransform="capitalize"
                bg={theme.primary} color={theme.light}
                _hover={{ bg: theme.primary }}
                isLoading={auth.isLoading}
              >
                {t('pages.auth.login')}
              </Button>

            </Stack>
          </form>
        </Box>
      </Box>


      <Box className="auth-bg" bg={theme.primary5}>
        <Image
          src={authBg}
        />
      </Box>
    </AuthWarpper>
  )
}

export default Login