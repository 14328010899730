import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { apiUri } from "../../utilities/apiManager";

let initialState = {
  isLoading: false,
  errors: [],
  data: {}
};

export const getPricing = createAsyncThunk(
  "pricing/getPricing",
  async (_, thunkApi) => {
    try {
      const { data } = await axios.get(
        `${apiUri}/pricing_manager`,
        {
          headers: {
            "auth-token": "Bearer " + thunkApi.getState().auth.accessToken,
            "Accept-Language": document.documentElement.lang,
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    }
    catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

export const updatePricing = createAsyncThunk(
  "pricing/updatePricing",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.post(
        `${apiUri}/pricing_manager`,
        args,
        {
          headers: {
            "auth-token": "Bearer " + thunkApi.getState().auth.accessToken,
            "Accept-Language": document.documentElement.lang,
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    }
    catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

const pricingSlice = createSlice({
  name: "pricing",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getPricing.pending, (state) => {
      state.isLoading = true;
      state.errors = [];
    })
      .addCase(getPricing.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.errors = [];
        state.data = payload.data;
      })
      .addCase(getPricing.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errors = payload.errors;
      })

      .addCase(updatePricing.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(updatePricing.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.errors = [];
        state.data = payload.data;
      })
      .addCase(updatePricing.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errors = payload.errors;
      })
  }
});


export default pricingSlice.reducer;