import { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

import ProtectedRoutes from './ProtectedRoutes';

import Login from '../modules/auth/Login';

const PropertyDetails = lazy(() => import('../modules/propertyDetails/PropertyDetails'));

const Profile = lazy(() => import('../modules/profile/Profile'));
const PersonalInfoTab = lazy(() => import('../modules/profile/tabs/PersonalInfoTab'));
const ChangePasswordTab = lazy(() => import('../modules/profile/tabs/ChangePasswordTab'));
const NotificationsTab = lazy(() => import('../modules/profile/tabs/NotificationsTab'));

// main
const Dashboard = lazy(() => import('../modules/main/dashboard/Dashboard'));
const Config = lazy(() => import("../modules/main/settings/config/Config"));
const Info = lazy(() => import("../modules/main/settings/info/Info"));
const TermsConditions = lazy(() => import("../modules/main/settings/termsConditions/TermsConditions"));
const PrivacyPolicy = lazy(() => import("../modules/main/settings/privacyPolicy/PrivacyPolicy"));
const RefundPolicy = lazy(() => import("../modules/main/settings/refundPolicy/RefundPolicy"));
const Commission = lazy(() => import("../modules/main/settings/commission/Commission"));
const Contracts = lazy(() => import("../modules/main/settings/contracts/Contracts"));
const Properties = lazy(() => import("../modules/main/properties/Properties"));
const Features = lazy(() => import("../modules/main/settings/features/Features"));
const Points = lazy(() => import("../modules/main/settings/points/Points"));
const Balance = lazy(() => import("../modules/main/settings/ownerBalance/Balance"));
const Videos = lazy(() => import("../modules/main/videos/Videos"));
const Faq = lazy(() => import("../modules/main/settings/faq/Faq"));
const Invoices = lazy(() => import("../modules/main/invoices/Invoices"));
const DiarcoCommission = lazy(() => import("../modules/main/diarcoCommission/Invoices"));
const BookeyFees = lazy(() => import("../modules/main/bookeyFees/Invoices"));
const Insurance = lazy(() => import("../modules/main/insurance/Invoices"));
const InstallmentInvoices = lazy(() => import("../modules/main/installmentsInvoices/InstallmentsInvoices"));
const InstallmentsDiarcoCommission = lazy(() => import("../modules/main/installmentsDiarcoCommission/InstallmentsInvoices"));
const InstallmentsBookeyFees = lazy(() => import("../modules/main/installmentsBookeyFees/InstallmentsInvoices"));
const Transfers = lazy(() => import("../modules/main/transfers/Transfers"));
const Users = lazy(() => import("../modules/main/users/Users"));
const Employees = lazy(() => import("../modules/main/employees/Employees"));
const FreeServices = lazy(() => import("../modules/main/freeServices/FreeServices"));
const FreeServicesRequests = lazy(() => import("../modules/main/freeServices/requests/Requests"));
const Disclaimers = lazy(() => import("../modules/main/disclaimers/Disclaimers"));
const EndContract = lazy(() => import("../modules/main/endContract/EndContract"));
const Otps = lazy(() => import("../modules/main/otps/Otps"));
const Prizes = lazy(() => import("../modules/main/prizes/Prizes"));
const PrizesRequests = lazy(() => import("../modules/main/prizes/requests/Requests"));

// consumer
const Consumers = lazy(() => import("../modules/counsmer/consumers/Consumers"));
const ConsumerProfile = lazy(() => import("../modules/counsmer/consumers/profile/Profile"));
const ConsumerDashboard = lazy(() => import("../modules/counsmer/consumers/profile/tabs/dashboard/Dashboard"));

const RentsTab = lazy(() => import("../modules/counsmer/consumers/profile/tabs/requests/rents/RentsTab"));
const ToursTab = lazy(() => import("../modules/counsmer/consumers/profile/tabs/requests/tours/ToursTab"));
const EndContractTab = lazy(() => import("../modules/counsmer/consumers/profile/tabs/requests/endContract/EndContractTab"));
const RentContractsTab = lazy(() => import("../modules/counsmer/consumers/profile/tabs/rentContracts/ContractsTab"));
const SavedTab = lazy(() => import("../modules/counsmer/consumers/profile/tabs/saved/SavedTab"));
const FollowsTab = lazy(() => import("../modules/counsmer/consumers/profile/tabs/follows/FollowsTab"));
const PointsTab = lazy(() => import("../modules/counsmer/consumers/profile/tabs/points/PointsTab"));
const InvoicesTab = lazy(() => import("../modules/counsmer/consumers/profile/tabs/invoices/InvoicesTab"));
const UserInstallments = lazy(() => import("../modules/counsmer/consumers/profile/tabs/installments/Installments"));
const UserDisclaimers = lazy(() => import("../modules/counsmer/consumers/profile/tabs/disclaimers/Disclaimers"));
const UserFreeServices = lazy(() => import("../modules/counsmer/consumers/profile/tabs/freeServices/FreeServices"));
const UserOtpRequest = lazy(() => import("../modules/counsmer/consumers/profile/tabs/otps/Otps"));
const UserInstallmentsInvoices = lazy(() => import("../modules/counsmer/consumers/profile/tabs/installmentsInvoices/InstallmentsInvoices"));
const ReportsTab = lazy(() => import("../modules/counsmer/consumers/profile/tabs/ReportsTab"));
const Tours = lazy(() => import("../modules/counsmer/tours/Tours"));
const Rents = lazy(() => import("../modules/counsmer/rents/Rents"));
const Installments = lazy(() => import("../modules/counsmer/installments/Installments"));
const UserInvoices = lazy(() => import("../modules/counsmer/invoices/Invoices"));
const UserPoints = lazy(() => import("../modules/counsmer/points/Points"));
const UserContracts = lazy(() => import("../modules/counsmer/contracts/Contracts"));

//owner
const Owners = lazy(() => import("../modules/owner/owners/Owners"));
const OwnerProfile = lazy(() => import("../modules/owner/owners/profile/Profile"));
const OwnerDashboard = lazy(() => import("../modules/owner/owners/profile/tabs/dashboard/Dashboard"));
const PropertiesTab = lazy(() => import("../modules/owner/owners/profile/tabs/properties/Properties"));
const PromotedTab = lazy(() => import("../modules/owner/owners/profile/tabs/promoted/Properties"));
const VideosTab = lazy(() => import("../modules/owner/owners/profile/tabs/videos/Videos"));
const OwnerPointsTab = lazy(() => import("../modules/owner/owners/profile/tabs/points/Points"));
const OwnerProperties = lazy(() => import("../modules/owner/properties/Properties"));
const OwnerVideos = lazy(() => import("../modules/owner/videos/Videos"));
const OwnerPoints = lazy(() => import("../modules/owner/points/Points"));
const OwnerPromoted = lazy(() => import("../modules/owner/promoted/Properties"));
const OwnerInvoicesTab = lazy(() => import("../modules/owner/owners/profile/tabs/invoices/Invoices"));
const OwnerInstallmentsTab = lazy(() => import("../modules/owner/owners/profile/tabs/installmentsInvoices/InstallmentsInvoices"));
const OwnerToursTab = lazy(() => import("../modules/owner/owners/profile/tabs/tours/ToursTab"));
const OwnerTransfersTab = lazy(() => import("../modules/owner/owners/profile/tabs/transfers/Transfers"));
const OwnerFreeServicesTab = lazy(() => import("../modules/owner/owners/profile/tabs/freeServices/FreeServices"));
const OwnerDisclaimersTab = lazy(() => import("../modules/owner/owners/profile/tabs/disclaimers/Disclaimers"));
const OwnerOtpTab = lazy(() => import("../modules/owner/owners/profile/tabs/otps/Otps"));

// erp
const GeneralExpenses = lazy(() => import("../modules/erp/generalExpenses/GeneralExpenses"));
const AdminExpenses = lazy(() => import("../modules/erp/adminExpenses/AdminExpenses"));
const TechExpenses = lazy(() => import("../modules/erp/techExpenses/TechExpenses"));
const Advance = lazy(() => import("../modules/erp/advance/Advance"));
const Bounce = lazy(() => import("../modules/erp/bounce/Bounce"));
const Deduction = lazy(() => import("../modules/erp/deduction/Deduction"));
const Salaries = lazy(() => import("../modules/erp/salaries/Salaries"));
const GeneralExpensesReport = lazy(() => import("../modules/erp/reports/generalExpenses/GeneralExpenses"));
const SalariesReport = lazy(() => import("../modules/erp/reports/salaries/Salaries"));
const PromotedReport = lazy(() => import("../modules/erp/reports/promoted/Properties"));
const PublishedReport = lazy(() => import("../modules/erp/reports/published/Properties"));

const ErpWallet = lazy(() => import("../modules/erp/wallet/Wallet"));
const ErpInsurance = lazy(() => import("../modules/erp/insurance/Invoices"));
const ErpRentCommission = lazy(() => import("../modules/erp/invoices/Invoices"));
const ErpInstallmentCommission = lazy(() => import("../modules/erp/installmentsInvoices/InstallmentsInvoices"));

const Navigations = () => {
  return (
    <Routes>
      <Route path='login' element={<Login />} />
      <Route element={<ProtectedRoutes />}>
        <Route path='' element={<Dashboard />} />
        <Route path="profile" element={<Profile />}>
          <Route path="" element={<PersonalInfoTab />} />
          <Route path="change_password" element={<ChangePasswordTab />} />
          <Route path="notifications" element={<NotificationsTab />} />
        </Route>

        {/* generic route */}
        <Route path='properties/:propertyId' element={<PropertyDetails />} />

        <Route path='users' element={<Users />} />
        <Route path='employees' element={<Employees />} />
        <Route path='users' element={<Users />} />
        <Route path='properties_requests' element={<Properties />} />
        <Route path='disclaimer_requests' element={<Disclaimers />} />
        <Route path='end_contract' element={<EndContract />} />
        {/* //rents */}
        <Route path='invoices' element={<Invoices />} />
        <Route path='diarco_commission' element={<DiarcoCommission />} />
        <Route path='bookey_fees' element={<BookeyFees />} />
        <Route path='insurance' element={<Insurance />} />

        {/* // installments */}
        <Route path='installment_invoices' element={<InstallmentInvoices />} />
        <Route path='installments_diarco_commission' element={<InstallmentsDiarcoCommission />} />
        <Route path='installments_bookey_fees' element={<InstallmentsBookeyFees />} />

        <Route path='settings/info' element={<Info />} />
        <Route path='settings/config' element={<Config />} />
        <Route path='settings/terms_conditions' element={<TermsConditions />} />
        <Route path='settings/privacy_policy' element={<PrivacyPolicy />} />
        <Route path='settings/refund_policy' element={<RefundPolicy />} />
        <Route path='settings/commission' element={<Commission />} />
        <Route path='settings/contracts' element={<Contracts />} />
        <Route path='settings/features' element={<Features />} />
        <Route path='settings/faq' element={<Faq />} />
        <Route path='settings/points' element={<Points />} />
        <Route path='settings/balance' element={<Balance />} />
        <Route path='videos' element={<Videos />} />
        <Route path='transfers' element={<Transfers />} />
        <Route path='free_services'>
          <Route path='' element={<FreeServices />} />
          <Route path='requests' element={<FreeServicesRequests />} />
        </Route>
        <Route path='prizes'>
          <Route path='' element={<Prizes />} />
          <Route path='requests' element={<PrizesRequests />} />
        </Route>
        <Route path='otps' element={<Otps />} />
        <Route path='consumer/consumers' element={<Consumers />} />
        <Route path='consumer/consumers/:user' element={<ConsumerProfile />}>
          <Route path="dashboard" element={<ConsumerDashboard />} />
          <Route path="rents" element={<RentsTab />} />
          <Route path="tours" element={<ToursTab />} />
          <Route path="end_contract" element={<EndContractTab />} />
          <Route path="saved" element={<SavedTab />} />
          <Route path="follows" element={<FollowsTab />} />
          <Route path="invoices" element={<InvoicesTab />} />
          <Route path="installments" element={<UserInstallments />} />
          <Route path="disclaimer" element={<UserDisclaimers />} />
          <Route path="free_services" element={<UserFreeServices />} />
          <Route path="otp" element={<UserOtpRequest />} />
          <Route path="installments_invoices" element={<UserInstallmentsInvoices />} />
          <Route path="rent_contracts" element={<RentContractsTab />} />
          <Route path="points" element={<PointsTab />} />
          <Route path="reports" element={<ReportsTab />} />
        </Route>
        <Route path='consumer/tours' element={<Tours />} />
        <Route path='consumer/rents' element={<Rents />} />
        <Route path='consumer/installments' element={<Installments />} />
        <Route path='consumer/invoices' element={<UserInvoices />} />
        <Route path='consumer/points' element={<UserPoints />} />
        <Route path='consumer/contracts' element={<UserContracts />} />
        <Route path='owner/owners' element={<Owners />} />
        <Route path='owner/owners/:owner' element={<OwnerProfile />}>
          <Route path="dashboard" element={<OwnerDashboard />} />
          <Route path="properties" element={<PropertiesTab />} />
          <Route path="promoted" element={<PromotedTab />} />
          <Route path="videos" element={<VideosTab />} />
          <Route path="points" element={<OwnerPointsTab />} />
          <Route path="invoices" element={<OwnerInvoicesTab />} />
          <Route path="installments" element={<OwnerInstallmentsTab />} />
          <Route path="tours" element={<OwnerToursTab />} />
          <Route path="transfers" element={<OwnerTransfersTab />} />
          <Route path="free_services" element={<OwnerFreeServicesTab />} />
          <Route path="disclaimer" element={<OwnerDisclaimersTab />} />
          <Route path="otp" element={<OwnerOtpTab />} />
        </Route>
        <Route path='owner/properties' element={<OwnerProperties />} />
        <Route path='owner/videos' element={<OwnerVideos />} />
        <Route path='owner/points' element={<OwnerPoints />} />
        <Route path='owner/promoted' element={<OwnerPromoted />} />

        <Route path="general_expenses" element={<GeneralExpenses />} />
        <Route path="admin_expenses" element={<AdminExpenses />} />
        <Route path="tech_expenses" element={<TechExpenses />} />
        <Route path="advance" element={<Advance />} />
        <Route path="bounce" element={<Bounce />} />
        <Route path="deduction" element={<Deduction />} />
        <Route path="salaries" element={<Salaries />} />
        <Route path="reports/general_expenses" element={<GeneralExpensesReport />} />
        <Route path="reports/salaries" element={<SalariesReport />} />
        <Route path="reports/promoted" element={<PromotedReport />} />
        <Route path="reports/published" element={<PublishedReport />} />
        <Route path="erp/insurance" element={<ErpInsurance />} />
        <Route path="wallet" element={<ErpWallet />} />
        <Route path="rent_commission" element={<ErpRentCommission />} />
        <Route path="installment_commission" element={<ErpInstallmentCommission />} />
        <Route path="*" element={<h1>page not found</h1>} />
      </Route>
    </Routes>
  )
}

export default Navigations