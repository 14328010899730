import { configureStore } from '@reduxjs/toolkit';

import authSlice from './auth/authSlice';
import sidebarSlice from './sidebar/sidebarSlice';

import notificationsSlice from "./notifications/notificationsSlice";
import staticsSlice from "./statics/staticsSlice";
import ownerStaticsSlice from "./statics/ownerStaticsSlice";
import userStaticsSlice from "./statics/userStaticsSlice";
import infoSlice from "./settings/infoSlice";
import pricingSlice from "./settings/pricingSlice";
import termsConditionsSlice from "./settings/termsConditionsSlice";
import privacyPolicySlice from "./settings/privacyPolicySlice";
import refundPolicySlice from "./settings/refundPolicySlice";
import contractsSlice from "./settings/contractsSlice";
import commissionSlice from "./settings/commissionSlice";
import transfersSlice from "./transfers/transfersSlice";
import videosSlice from "./videos/videosSlice";
import featuresSlice from "./settings/featuresSlice";
import faqSlice from "./settings/faqSlice";
import employeesSlice from "./employees/employeesSlice";
import usersSlice from './users/usersSlice';
import freeServicesSlice from "./freeServices/freeServicesSlice";
import freeServicesRequestsSlice from "./freeServices/freeServicesRequestsSlice";
import disclaimersSlice from "./disclaimers/disclaimersSlice";
import otpsSlice from "./otps/otpsSlice";
import propertiesSlice from "./properties/propertiesSlice";
import rentsSlice from "./rents/rentsSlice";
import toursSlice from "./tours/toursSlice";
import endContractSlice from "./endContract/endContractSlice";
import savedSlice from "./saved/savedSlice";
import generalExpensesSlice from "./generalExpenses/generalExpensesSlice";
import adminExpensesSlice from "./adminExpenses/adminExpensesSlice";
import techExpensesSlice from "./techExpenses/techExpensesSlice";
import advanceSlice from "./advance/advanceSlice";
import bounceSlice from "./bounce/bounceSlice";
import deductionSlice from "./deduction/deductionSlice";
import salariesSlice from "./salaries/salariesSlice";
import followsSlice from "./follows/followsSlice";
import pointsSlice from "./points/pointsSlice";
import installmentsSlice from "./installments/installmentsSlice";
import installmentsInvoicesSlice from "./installments/installmentsInvoicesSlice";
import ownerPlansSlice from "./ownerPlans/ownerPlansSlice";
import invoicesSlice from "./invoices/invoicesSlice";
import walletSlice from "./wallet/walletSlice";
import prizesSlice from "./prizes/prizesSlice";
import prizesRequestsSlice from "./prizes/prizesRequestsSlice";
import balanceSlice from "./balance/balanceSlice";
import insuranceSlice from "./insurance/insuranceSlice";

export default configureStore({
  reducer: {
    sidebar: sidebarSlice,
    auth: authSlice,
    notifications: notificationsSlice,
    statics: staticsSlice,
    ownerStatics: ownerStaticsSlice,
    userStatics: userStaticsSlice,
    info: infoSlice,
    pricing: pricingSlice,
    termsConditions: termsConditionsSlice,
    privacyPolicy: privacyPolicySlice,
    refundPolicy: refundPolicySlice,
    contracts: contractsSlice,
    commission: commissionSlice,
    transfers: transfersSlice,
    videos: videosSlice,
    features: featuresSlice,
    faq: faqSlice,
    ownerPlans: ownerPlansSlice,
    employees: employeesSlice,
    users: usersSlice,
    freeServices: freeServicesSlice,
    freeServicesRequests: freeServicesRequestsSlice,
    disclaimers: disclaimersSlice,
    otps: otpsSlice,
    properties: propertiesSlice,
    rents: rentsSlice,
    tours: toursSlice,
    installments: installmentsSlice,
    installmentsInvoices: installmentsInvoicesSlice,
    endContract: endContractSlice,
    saved: savedSlice,
    follows: followsSlice,
    generalExpenses: generalExpensesSlice,
    adminExpenses: adminExpensesSlice,
    techExpenses: techExpensesSlice,
    advance: advanceSlice,
    bounce: bounceSlice,
    deduction: deductionSlice,
    salaries: salariesSlice,
    points: pointsSlice,
    invoices: invoicesSlice,
    wallet: walletSlice,
    prizes: prizesSlice,
    prizesRequests: prizesRequestsSlice,
    balance: balanceSlice,
    insurance: insuranceSlice
  },
});