import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiUri, filterRequest } from "../../utilities/apiManager";

let initialState = {
  isLoading: false,
  data: [],
  userDetails: null,
  itemsCount: 0,
  pages: 0,
  errors: []
}

export const getUsers = createAsyncThunk(
  "users/getUsers",
  async (args, thunkApi) => {
    try {
      const { page, size = 10, query } = args;
      const filter = filterRequest(args.filter);
      const { data } = await axios.get(
        `${apiUri}/users/all?page=${page}&size=${size}&query=${query}${filter}`,
        {
          headers: {
            'Content-Type': 'application/json',
            "auth-token": "Bearer " + thunkApi.getState().auth.accessToken,
            "Accept-Language": document.documentElement.lang,
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

export const createUser = createAsyncThunk(
  "users/createUser",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.post(
        `${apiUri}/users`,
        args,
        {
          headers: {
            'Content-Type': 'application/json',
            "auth-token": "Bearer " + thunkApi.getState().auth.accessToken,
            "Accept-Language": document.documentElement.lang,
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    }
    catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

export const updatePassword = createAsyncThunk(
  "users/updatePassword",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.put(
        `${apiUri}/users/update_password/${args._id}`,
        args,
        {
          headers: {
            'Content-Type': 'application/json',
            "auth-token": "Bearer " + thunkApi.getState().auth.accessToken,
            "Accept-Language": document.documentElement.lang,
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    }
    catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

export const updateUser = createAsyncThunk(
  "users/updateUser",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.put(
        `${apiUri}/users/${args._id}`,
        args.values,
        {
          headers: {
            'Content-Type': 'application/json',
            "auth-token": "Bearer " + thunkApi.getState().auth.accessToken,
            "Accept-Language": document.documentElement.lang,
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    }
    catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

export const deleteUser = createAsyncThunk(
  "users/deleteUser",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.delete(
        `${apiUri}/users/${args._id}`,
        {
          headers: {
            "auth-token": "Bearer " + thunkApi.getState().auth.accessToken,
            "Accept-Language": document.documentElement.lang,
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    }
    catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

export const getUserDetails = createAsyncThunk(
  "users/getUserDetails",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `${apiUri}/users/${args.user}`,
        {
          headers: {
            "auth-token": "Bearer " + thunkApi.getState().auth.accessToken,
            "Accept-Language": document.documentElement.lang,
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    }
    catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
)

const usersSlice = createSlice({
  name: "users",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(getUsers.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = payload.data;
        state.itemsCount = payload.itemsCount;
        state.pages = payload.pages;
        state.errors = [];
      })
      .addCase(getUsers.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errors = payload.errors
      })

      .addCase(createUser.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(createUser.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data.push(payload.data);
        state.itemsCount++;
        state.errors = [];
      })
      .addCase(createUser.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errors = payload.errors;
      })

      .addCase(updateUser.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(updateUser.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        const indexAt = state.data.findIndex(el => el._id === payload.data._id);
        state.data[indexAt] = payload.data;
        state.errors = [];
      })
      .addCase(updateUser.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errors = payload.errors;
      })

      .addCase(updatePassword.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(updatePassword.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.errors = [];
        const indexAt = state.data.findIndex(el => el._id === payload.data._id);
        state.data[indexAt] = payload.data;
      })
      .addCase(updatePassword.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errors = payload.errors;
      })

      .addCase(deleteUser.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(deleteUser.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = state.data.filter(el => el._id !== payload.data._id);
        state.errors = [];
      })
      .addCase(deleteUser.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errors = payload.errors;
      })

      .addCase(getUserDetails.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(getUserDetails.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.errors = [];
        state.userDetails = payload.data;
      })
      .addCase(getUserDetails.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errors = payload.errors;
      })
  }
});

export default usersSlice.reducer;