import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiUri, filterRequest } from "../../utilities/apiManager";

let initialState = {
  isLoading: false,
  data: [],
  itemsCount: 0,
  pages: 0,
  errors: [],
}

export const getOtps = createAsyncThunk(
  "otps/getOtps",
  async (args, thunkApi) => {
    try {
      const { page, size = 10, query } = args;
      const filter = filterRequest(args.filter);
      const { data } = await axios.get(
        `${apiUri}/otp_requests?page=${page}&size=${size}&query=${query}${filter}`,
        {
          headers: {
            "Content-Type": "application/json",
            "Accept-Language": document.documentElement.lang,
            "auth-token": thunkApi.getState().auth.accessToken
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      console.log(errors);
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

export const updateOtp = createAsyncThunk(
  "otps/updateOtp",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.put(
        `${apiUri}/otp_requests/${args._id}`,
        args,
        {
          headers: {
            "Content-Type": "application/json",
            "Accept-Language": document.documentElement.lang,
            "auth-token": thunkApi.getState().auth.accessToken
          }
        }
      );
      return thunkApi.fulfillWithValue(data);
    }
    catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

const otpsSlice = createSlice({
  name: "otps",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getOtps.pending, (state) => {
      state.isLoading = true;
      state.errors = [];
    })
    builder.addCase(getOtps.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = payload.data;
      state.pages = payload.pages;
      state.itemsCount = payload.itemsCount;
      state.errors = [];
    })
    builder.addCase(getOtps.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload.errors;
    })

    builder.addCase(updateOtp.pending, (state) => {
      state.isLoading = true;
      state.errors = [];
    })
    builder.addCase(updateOtp.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      const indexAt = state.data.findIndex(el => el._id === payload.data._id);
      state.data[indexAt] = payload.data;
      state.errors = [];
    })
    builder.addCase(updateOtp.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload.errors;
    })
  }
});

export default otpsSlice.reducer;